import {ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {MatSort} from '@angular/material/sort';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {GiftService} from '../../../shared/services/gift.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {StatsService} from '../../../shared/services/stats.service';
import {DateService, GiftUser, UserService} from '@isifid/core';
import {Router} from '@angular/router';
import {SponsorshipService} from '../../../shared/services/sponsorship.service';
import {ExcelService} from '@isifid/reward';
import {MatTableDataSource} from '@angular/material/table';
import {GiftUserService} from '../../../shared/services/gift-user.service';
import {filter} from 'rxjs/operators';
import {MatPaginator} from '@angular/material/paginator';
import {TitleCasePipe} from '@angular/common';

@Component({
    selector: 'app-tracking',
    templateUrl: './tracking.component.html',
    standalone: false
})
export class TrackingComponent implements OnInit {
    colNames: Array<Array<string>>;
    displayedColumns: Array<Array<string>>;

    dataSourceSponsors = new MatTableDataSource<any>();
    dataSourceSponsored = new MatTableDataSource<any>();
    dataSourceGiftUsers = new MatTableDataSource<any>();
    loading = true;

    sponsors = [];
    sponsored = [];

    giftUsersStats = [];
    giftUser: GiftUser;

    trackingForm: FormGroup;

    private sponsorshipOperation: any;
    private professionalEnabled = false;

    @ViewChild('sponsorSort') sponsorSort: MatSort;
    @ViewChild('giftUsersSort') giftUsersSort: MatSort;
    @ViewChild('sponsoredSort') sponsoredSort: MatSort;
    @ViewChild('sponsorPaginator') sponsorPaginator: MatPaginator;
    @ViewChild('sponsoredPaginator') sponsoredPaginator: MatPaginator;
    @ViewChild('giftUsersPaginator') giftUsersPaginator: MatPaginator;

    constructor(
        private cdRef: ChangeDetectorRef,
        readonly giftService: GiftService,
        private readonly giftUserService: GiftUserService,
        private readonly sponsorshipService: SponsorshipService,
        private readonly statsService: StatsService,
        private readonly userService: UserService,
        private readonly excelService: ExcelService,
        private readonly formBuilder: FormBuilder,
        private readonly dateService: DateService,
        private readonly router: Router,
        private readonly _snackBar: MatSnackBar,
        private titleCase: TitleCasePipe
    ) {
    }

    ngOnInit(): void {
        this.giftUserService.getGiftUser().pipe(filter(s => !!s)).subscribe(s => {
            this.giftUser = s;
            if (!this.giftUser.branchList) this.giftUser.branchList = [];
        });
        this.initColumns();
        this.initOperation();
        this.resetForm(true);
        this.loading = false;
    }

    initColumns() {
        let customId = this.giftService.giftNetworkVariables.idName || 'ID';
        customId = this.titleCase.transform(customId);
        this.displayedColumns = this.statsService.getSponsorshipTrackingDisplayedColumns();
        this.colNames = this.statsService.getSponsorshipTrackingColNames(customId);
    }

    initOperation() {
        this.sponsorshipOperation = this.sponsorshipService.operation;
        if (!this.sponsorshipOperation) this._snackBar.open('Aucune opération de parrainage', 'X');

        if (this.sponsorshipService.settings?.professionalEnabled) {
            this.professionalEnabled = true;
            this.displayedColumns[0].splice(6, 0, 'isPro');
            this.colNames[0].splice(6, 0, 'Professionnel');
            this.displayedColumns[1].splice(10, 0, 'isPro');
            this.colNames[1].splice(10, 0, 'Professionnel');
        }
    }

    resetForm(resetInputs: boolean = true): void {
        this.dataSourceSponsors = new MatTableDataSource<any>();
        this.dataSourceSponsored = new MatTableDataSource<any>();
        this.dataSourceGiftUsers = new MatTableDataSource<any>();

        this.sponsors = [];
        this.sponsored = [];
        this.giftUsersStats = [];

        if (resetInputs) {
            this.trackingForm = this.formBuilder.group({
                start: [this.dateService.computeDate(this.sponsorshipOperation.startDate, 'yyyy-MM-dd'), [Validators.required, Validators.minLength(10),
                    Validators.maxLength(10)]],
                end: [this.dateService.computeDate(new Date(), 'yyyy-MM-dd'), Validators.required],
                inputBranchCode: this.giftUser.branchList.length == 1 ? this.giftUser.branchList[0] : '',
                sponsorsOnly: this.hasRole('GIFT_ADVISOR'),
                preDefinedRange: 'all'
            });
            this.trackingForm.controls.preDefinedRange.valueChanges.subscribe(res => this.updateRange(res));
        }
    }

    hasRole(role: string): boolean {
        return this.userService.hasRole(role);
    }

    getStats(): void {
        if (this.trackingForm.invalid) return;

        this.resetForm(false);
        this.loading = true;

        const filters = {
            start: this.dateService.computeDate(this.trackingForm.get('start').value, 'yyyy-MM-dd'),
            end: this.dateService.computeDate(this.trackingForm.get('end').value, 'yyyy-MM-dd', 1)
        };

        // Filter by gift user id
        if (this.trackingForm.get('sponsorsOnly').value) filters['giftUserId'] = this.giftUser.id;

        // Filter by branch
        if (this.trackingForm.get('inputBranchCode').value) filters['branchList'] = this.trackingForm.get('inputBranchCode').value;
        else if (this.giftUser.branchList.length) filters['branchList'] = this.giftUser.branchList;

        this.statsService.getSponsorshipStat(this.sponsorshipOperation.id, [], filters).subscribe({
            next: (stats) => {
                this.sponsors = this.statsService.formatSponsorStats(stats.sponsorStats, this.sponsors, false,
                    this.professionalEnabled);
                this.sponsored = this.statsService.formatSponsoredStats(stats.sponsoredStats, this.sponsored, false,
                    this.professionalEnabled);

                this.dataSourceSponsors = new MatTableDataSource<unknown>(this.sponsors);
                this.dataSourceSponsors.sort = this.sponsorSort;
                this.dataSourceSponsors.paginator = this.sponsorPaginator;
                this.dataSourceSponsored = new MatTableDataSource<unknown>(this.sponsored);
                this.dataSourceSponsored.sort = this.sponsoredSort;
                this.dataSourceSponsored.paginator = this.sponsoredPaginator;
                this.cdRef.detectChanges();
            }, error: () => {
                this._snackBar.open('Une erreur est survenue', 'X');
                this.loading = false;
            }, complete: () => {
                this.loadGiftUsersTab();
            }
        });
    }

    loadGiftUsersTab() {
        this.sponsors
            .filter(s => s.giftUserEmail)
            .forEach(row => {
                // Don't add duplicates, a gift user is considered as duplicate if it has the same email
                if (this.giftUsersStats.some(g => g.email === row.giftUserEmail)) return;

                const giftUserStat = {
                    id: row.giftUserId,
                    firstName: row.giftUserFirstName,
                    lastName: row.giftUserLastName,
                    email: row.giftUserEmail,
                    branchCode: row.branchCode,
                    sponsorCount: 0,
                    sponsoredCount: 0
                };

                const sponsorCount = this.sponsors
                    .filter(t => t.giftUserEmail === row.giftUserEmail).length;
                const sponsoredCount = this.sponsored
                    .filter(t => t.giftUserEmail === row.giftUserEmail).length;

                if (sponsorCount) giftUserStat.sponsorCount = sponsorCount;
                if (sponsoredCount) giftUserStat.sponsoredCount = sponsoredCount;

                this.giftUsersStats.push(giftUserStat);
            });

        this.dataSourceGiftUsers = new MatTableDataSource<unknown>(this.giftUsersStats);
        this.dataSourceGiftUsers.sort = this.giftUsersSort;
        this.dataSourceGiftUsers.paginator = this.giftUsersPaginator;
        this.cdRef.detectChanges();
        this.loading = false;
    }

    download(): void {
        if (!this.sponsors) return;
        const aoa: any[] = [[], [], []];

        // Generate first tab
        this.setFirstTab(aoa[0]);
        // Generate sponsored tab
        this.setSponsoredTab(aoa[1]);
        // Generate aggregated giftUser stats tab
        this.setAggregatedGiftUserStatsTab(aoa[2]);

        const fileName = this.sponsorshipOperation.name;
        this.excelService.exportAsExcelFileAoA(
            aoa,
            `${fileName}_${new Date().toISOString().substring(0, 10)}`,
            ['Liste des parrains', 'Liste des filleuls', 'Liste agrégée']
        );
    }

    private updateRange(value) {
        this.dataSourceSponsors = undefined;
        const start = new Date();
        const end = new Date();
        if (value === '7') {
            start.setDate(start.getDate() - 7);
        } else if (value === '30') {
            start.setDate(end.getDate() - 30);
        } else if (value === 'year') {
            start.setDate(1);
            start.setMonth(0);
        }
        this.trackingForm.get('start').setValue(this.dateService.computeDate(start, 'yyyy-MM-dd'));
        this.trackingForm.get('end').setValue(this.dateService.computeDate(end, 'yyyy-MM-dd'));
    }

    private setFirstTab(aoa): void {
        aoa.push(this.colNames[0]);
        // If displayBranches add branchName
        this.sponsors.forEach(sponsorStats => {
            if (this.professionalEnabled) {
                aoa.push([sponsorStats.email, sponsorStats.externalId, sponsorStats.firstName, sponsorStats.lastName,
                    sponsorStats.mobile, sponsorStats.sponsoredCount, sponsorStats.isPro,
                    this.dateService.computeDate(new Date(sponsorStats.sponsorCodeSentAt), 'dd/MM/yyyy'),
                    sponsorStats.giftUserEmail, sponsorStats.branchCode]);
            } else {
                aoa.push([sponsorStats.email, sponsorStats.externalId, sponsorStats.firstName, sponsorStats.lastName,
                    sponsorStats.mobile, sponsorStats.sponsoredCount,
                    this.dateService.computeDate(new Date(sponsorStats.sponsorCodeSentAt), 'dd/MM/yyyy'),
                    sponsorStats.giftUserEmail, sponsorStats.branchCode]);
            }
        });
        aoa.push(['']);
        aoa.push(['']);
        if (this.trackingForm?.get('sponsorsOnly').value) {
            aoa.push(['Suivi Parrainage Conseiller', this.userService.getUser().lastName, this.userService.getUser().firstName,
                'Email', this.userService.getUser().email]);
        } else {
            aoa.push(['Suivi Parrainage Point de vente : ', this.giftUser.branchList[0]]);
        }
        const fileName = this.sponsorshipOperation.name;
        aoa.push(['Opération : ', fileName]);
    }

    private setSponsoredTab(aoa): void {
        aoa.push(this.colNames[1]);

        if (this.sponsored.length === 0) {
            aoa.push(['Liste des filleuls']);
        } else {
            for (const sponsored of this.sponsored) {
                if (this.professionalEnabled) {
                    aoa.push(
                        [sponsored.sponsorEmail, sponsored.sponsorExternalId, sponsored.sponsorFirstName, sponsored.sponsorLastName,
                            sponsored.sponsorMobile, sponsored.email, sponsored.externalId, sponsored.firstName, sponsored.lastName,
                            sponsored.mobile, sponsored.isPro, this.dateService.computeDate(new Date(sponsored.rewardedAt), 'dd/MM/yyyy'),
                            sponsored.sponsoredCodeSentAt ?
                                this.dateService.computeDate(new Date(sponsored.sponsoredCodeSentAt), 'dd/MM/yyyy') : '']
                    );
                } else {
                    aoa.push(
                        [sponsored.sponsorEmail, sponsored.sponsorExternalId, sponsored.sponsorFirstName, sponsored.sponsorLastName,
                            sponsored.sponsorMobile, sponsored.email, sponsored.externalId, sponsored.firstName, sponsored.lastName,
                            sponsored.mobile, this.dateService.computeDate(new Date(sponsored.rewardedAt), 'dd/MM/yyyy'),
                            sponsored.sponsoredCodeSentAt ?
                                this.dateService.computeDate(new Date(sponsored.sponsoredCodeSentAt), 'dd/MM/yyyy') : '']
                    );
                }
            }
        }
    }

    private setAggregatedGiftUserStatsTab(aoa): void {
        aoa.push(this.colNames[2]);

        if (this.giftUsersStats.length === 0) {
            aoa.push(['Liste agrégée']);
        } else {
            for (const giftUserStat of this.giftUsersStats) {
                aoa.push([giftUserStat.firstName, giftUserStat.lastName, giftUserStat.email, giftUserStat.branchCode,
                    giftUserStat.sponsorCount, giftUserStat.sponsoredCount]);
            }
        }
    }
}
