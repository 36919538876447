@if (!loading) {
    <div class="container-fluid m-0 pt-5" id="not-print">

        <div class="row">
            <div class="col text-center">

                <h1>J'oriente mon QR code vers mon client afin qu'il le flashe</h1>

                <div class="row">
                    <div class="col text-center my-3">
                        <p class="small">Attention votre client doit s'inscrire immédiatement*.</p>
                    </div>
                </div>
                <div class="text-center">
                    @if (qrcodeValue) {
                        <qrcode [qrdata]="qrcodeValue"
                                errorCorrectionLevel="L"
                                size="300"/>
                    }
                </div>
                <div class="row">
                    <p class="col text-center mt-4 small fst-italic">
                        *seul le flashage de votre QR code lui permet de s’inscrire sur son espace parrainage.
                    </p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col text-center">
                <h2>Vous pouvez également envoyer ce lien par email, il est l'équivalent du QR Code.</h2>
                <a [href]="getUrl('link')" target="_blank">{{ getUrl('link') }}</a>
            </div>
        </div>

        <div class="row my-5">
            <div class="col text-center">
                <h2>Je préfère imprimer mon QR code sur papier</h2>
                <p class="mt-4 lh-lg">
                    Plutôt qu’orienter mon écran vers mon client, j’imprime mon QR code sur un<br>
                    document papier qui invite mes clients satisfaits à le flasher.<br>
                    Si je dispose d’un présentoir, je glisse la feuille à l’intérieur.
                </p>
                <div class="row px-5">
                    <div class="col">
                        <p>Vous êtes une conseillère, cliquez ici </p>
                        <button (click)="print(true)" class="btn btn-validate p-3 my-2 text-nowrap">
                            <i aria-hidden="true" class="fa-1x fas fa-print me-2"></i>
                            Imprimer
                        </button>
                    </div>
                    <div class="col">
                        <p>Vous êtes un conseiller, cliquez ici </p>
                        <button (click)="print()" class="btn btn-validate p-3 my-2 text-nowrap">
                            <i aria-hidden="true" class="fa-1x fas fa-print me-2"></i>
                            Imprimer
                        </button>
                    </div>
                </div>
            </div>
        </div>

    </div>

}

<!-- QRCode Modal -------------------------------------------------------------------------------------------------- -->
<div (click)="showModal = !showModal" (keydown)="showModal = !showModal"
     class="modal {{showModal ? 'd-block' : 'd-none'}}" id="myModal">
    <span class="close text-black not-printable">&times;</span>

    <div class="col-6 mx-auto" id="print">

        <div class="row">
            <div class="col-4 ms-auto pt-3">
                <img [src]="logoUrl" alt="logo" id="logo" height="" width="250">
                <p class="mt-4 fw-bold">Vous appréciez notre relation ?<br>
                    J'en suis ravi
                    @if (giftUserIsFemale) {e}, moi aussi !<br>
                    Accepteriez-vous d'en faire part à vos proches ?
                </p>
            </div>
            <div class="col-4 mx-auto">
                <div id="heart" [style.--client-color]="clientColor"></div>
                <div id="heart-text" class="text-white">
                    @if (!giftUserIsFemale) {
                        <span>J'aime mon<br>conseiller !</span>
                    } @else {
                      <span>J'aime ma<br>conseillère !</span>
                    }
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-4 ms-auto my-auto">
                <p id="text-left-qrcode">Moins d'une minute vous suffira pour flasher mon QR code et obtenir votre code parrain...
                    le sésame qui procure du pouvoir d’achat, à vous comme à vos proches !</p>
            </div>
            <div class="col-4 mx-auto text-center" id="qrcode">
                @if (qrcodeValue) {
                    <qrcode [qrdata]="qrcodeValue"
                             class="modal-content border-none"
                             errorCorrectionLevel="L"
                             id="qrcodeUser"
                             size="350" />
                }
            </div>
        </div>

        <div class="row">
            <div class="col-6 mx-auto ps-5">
                <img [src]="env.cdnUrl + '/not-cached/gift/img/sponsorship/smartphone-slide1.png'"
                     alt="slide 1" class="d-block mx-auto ps-5" height="40%" width="auto" id="smartphone">
            </div>
        </div>

    </div>
</div>

