import {Component, Input, OnInit} from '@angular/core';
import {ConsumerSmall} from '../../consumers/models/consumerSmall.model';
import {SponsorshipService} from '../../../shared/services/sponsorship.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {environment} from '../../../../environments/environment';
import {Router} from '@angular/router';
import {Offer} from '@isifid/core';
import {GiftService} from '../../../shared/services/gift.service';

@Component({
    selector: 'app-sponsored',
    templateUrl: './sponsored.component.html',
    styleUrls: ['./sponsored.component.scss'],
    standalone: false
})
export class SponsoredComponent implements OnInit {
    @Input() action: string;
    consumerSponsor: ConsumerSmall;
    loading = true;
    offersSponsor: void | Offer[];
    private env = environment;

    constructor(
        public readonly giftService: GiftService,
        private router: Router,
        private readonly sponsorshipService: SponsorshipService,
        private readonly _snackBar: MatSnackBar
    ) {
    }

    ngOnInit() {
        if (!this.sponsorshipService.operation) {
            this._snackBar.open('Aucune opération de parrainage trouvée', 'X', {duration: this.env.duration});
        }
    }

    validateEvent(event: { action: string; sponsor: ConsumerSmall; offersSponsor: void | Offer[] }): void {
        if (!event?.action || !event.sponsor || !event.offersSponsor?.[0]) return;
        this.consumerSponsor = event.sponsor;
        this.offersSponsor = event.offersSponsor;
        this.changeAction(event.action);
    }

    changeAction($event: string): void {
        this.action = $event;
        this.router.navigate(['sponsorship', 'sponsored', this.action]).then();
    }
}
