import {Component, OnInit} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {GiftService} from '../../../shared/services/gift.service';
import {SponsorshipService} from '../../../shared/services/sponsorship.service';
import {MsConsumersService, MsServicesSponsorshipService, SponsorshipContent, UserService} from '@isifid/core';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';
import {forkJoin} from 'rxjs';

@Component({
    selector: 'app-discover',
    templateUrl: './discover.component.html',
    styleUrls: ['./discover.component.scss'],
    standalone: false
})
export class DiscoverComponent implements OnInit {
    step = 0;
    showMoreRegister: boolean = false;
    showMoreReferral: boolean = false;
    env = environment;
    emailPreviewSponsored: SafeHtml = '';
    showSms: boolean = false;
    smsPreview: string = '';
    showSecondSlideStep2: boolean = false;
    showSocialNetwork: boolean = false;
    sharePreview: string = '';
    private emailPreviewSponsor: string = '';

    constructor(
        private readonly giftService: GiftService,
        private readonly msConsumersService: MsConsumersService,
        private readonly msServicesSponsorshipService: MsServicesSponsorshipService,
        private readonly sponsorshipService: SponsorshipService,
        private readonly userService: UserService,
        private sanitizer: DomSanitizer
    ) {
    }

    ngOnInit() {
        this.previewEmailSms();
    }

    previewEmailSms() {
        const emailPreviewRequest = {
            clientId: this.giftService.client.id,
            operationId: this.sponsorshipService.settings.operationId,
            type: 'sponsored'
        };
        const emailPreviewRequestSponsored = {
            clientId: this.giftService.client.id,
            operationId: this.sponsorshipService.settings.operationId,
            type: 'sponsor'
        };
        forkJoin([
            this.msConsumersService.createEmailPreview(emailPreviewRequest),
            this.msConsumersService.createEmailPreview(emailPreviewRequestSponsored),
            this.msServicesSponsorshipService.getContent(this.sponsorshipService.settings.id)
        ]).subscribe(([emailPreviewSponsored, emailPreviewSponsor, content]) => {
            this.emailPreviewSponsor = this.replaceVariableInEmailPreview(emailPreviewSponsor, content);
            this.emailPreviewSponsored = this.sanitizer.bypassSecurityTrustHtml(this.replaceVariableInEmailPreview(emailPreviewSponsored, content));
            this.smsPreview = content.invitationSmsText;
            this.sharePreview = content.validationSocialText;
        });
    }

    replaceVariableInEmailPreview(emailPreview, content: SponsorshipContent): any {
        content.sponsoredSponsorshipText = content.sponsoredSponsorshipText.replace(/\\n/g, '<br>');
        emailPreview = emailPreview.replace(/\$\{personalizedContent}/gi, content.sponsoredSponsorshipText);
        emailPreview = emailPreview.replace(/\$\{clientName}/gi, this.giftService.client.name);
        emailPreview = emailPreview.replace(/\$\{sponsoredFirstName}/gi, this.userService.getUser().firstName);
        emailPreview = emailPreview.replace(/\$\{sponsorCode}/gi, 'demo');
        emailPreview = emailPreview.replace(/\$\{sponsorCodeHashtag}/gi, content.sponsorCodeHashtag);
        emailPreview = emailPreview.replace(/\$\{programUrl}/gi, this.sponsorshipService.settings.domain);
        emailPreview = emailPreview.replace(/\$\{sponsored_amount}/gi, this.sponsorshipService.settings.sponsoredAmount);
        emailPreview = emailPreview.replace(/\$\{sponsoredAmount}/gi, this.sponsorshipService.settings.sponsoredAmount);
        emailPreview = emailPreview.replace(/\$\{sponsorFirstName} \$\{sponsorLastName}/gi, 'Jeanne Dupont');
        emailPreview = emailPreview.replace(/\$\{shortGender}/gi, 'Mme');
        emailPreview = emailPreview.replace(/\$\{sponsor_amount}/gi, this.sponsorshipService.settings.sponsorAmount);
        emailPreview = emailPreview.replace(/href/gi, '');
        return emailPreview;
    }

    openPreview(type: string) {
        const w = window.open('', '_blank');
        if (type === 'email') w.document.write(this.emailPreviewSponsor);
        else if (type === 'sms') w.document.write(this.smsPreview);
        else if (type === 'social') w.document.write(this.sharePreview);
    }
}
