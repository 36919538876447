import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ConfigurationService, Consumer, Offer} from '@isifid/core';
import {ConsumerSmall} from '../../../consumers/models/consumerSmall.model';
import {GiftService} from '../../../../shared/services/gift.service';
import {SponsorshipService} from '../../../../shared/services/sponsorship.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ConsumerService} from '../../../../shared/services/consumer.service';
import {UIService} from '../../../../shared/services/ui.service';
import {Observable, switchMap, tap} from 'rxjs';

@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    standalone: false
})
export class RegisterComponent implements OnInit {
    consumerSponsored: Consumer;
    loading= true;
    rewarding: boolean;
    rewardSponsoredSuccessful: boolean;
    rewardSponsorSuccessful: boolean;
    sponsorCreated: boolean;
    sponsoredAlreadyRewarded: boolean;
    private mortgageLoan: boolean = false;
    private selfCare: boolean = false;
    private offersSponsored: void | Offer[];
    private sponsoredRewardAttributes = [];

    @Input() consumerSponsor: ConsumerSmall;
    @Input() offersSponsor: void | Offer[];
    @Output() actionEvent = new EventEmitter<string>();

    constructor(
        public readonly giftService: GiftService,
        public readonly sponsorshipService: SponsorshipService,
        public readonly uiService: UIService,
        private readonly configurationService: ConfigurationService,
        private readonly consumerService: ConsumerService,
        private readonly _snackBar: MatSnackBar
    ) {
    }

    ngOnInit() {
        // Redirect to validate
        if (!this.consumerSponsor || !this.offersSponsor?.[0]) this.actionEvent.emit('validate');
        this.loading = false;
    }

    checkEligibilityBeforeRewarding(event: { sponsor: ConsumerSmall; sponsored: Consumer; rewardProperties: any[]; mortgageLoan: boolean; selfCare: boolean }) {
        if (!event.sponsor || !event.sponsored) return;
        this.consumerSponsor = event.sponsor;
        this.consumerSponsored = event.sponsored;
        this.sponsoredRewardAttributes = event.rewardProperties;
        this.mortgageLoan = event.mortgageLoan;
        this.selfCare = event.selfCare;

        if (this.canRewardForSponsorship()) this.getOffersSponsored().subscribe(() => this.rewardBoth());
    }

    canRewardForSponsorship(): boolean {
        return this.consumerSponsor != null && this.sponsorshipService.operation != null && this.consumerSponsored != null;
    }

    getOffersSponsored(): Observable<void | Offer[]> {
        let isProSponsored = false;

        if (this.sponsorshipService.settings.professionalEnabled) {
            if (this.configurationService.getValueByKey(this.consumerSponsored?.configuration, 'typology') === 'pro') isProSponsored = true;
        }

        // Get the sponsored offer
        return this.sponsorshipService.getSponsoredOffers(isProSponsored)
            .pipe(
                tap(offers => {
                    this.offersSponsored = offers;
                    if (!this.offersSponsored?.[0]) {
                        console.error('Missing sponsored offers for sponsorship -> ' + this.sponsorshipService.operation.id);
                        this._snackBar.open('Une erreur est survenue. Merci de contacter le support', 'X');
                    }
                })
            );
    }

    private rewardBoth() {
        this.rewardSponsorSuccessful = false;
        this.rewardSponsoredSuccessful = false;

        // Add sponsor code to reward attributes if not already present
        if (this.sponsoredRewardAttributes.indexOf(this.consumerSponsor.sponsorCode) === -1) {
            this.sponsoredRewardAttributes.unshift(this.consumerSponsor.sponsorCode);
        }

        if (this.offersSponsored) this.offersSponsored = this.filterOffersByConfiguration(this.offersSponsored);
        if (this.offersSponsor) this.offersSponsor = this.filterOffersByConfiguration(this.offersSponsor);

        if (this.offersSponsored?.[0]) {
            // Reward sponsored first to avoid sponsor to be rewarded if sponsored reward failed
            this.consumerService.rewardOne(this.consumerSponsored, this.offersSponsored?.[0], this.sponsoredRewardAttributes).subscribe({
                next: (r) => {
                    this.rewardSponsoredSuccessful = r.nbOfNonRewarded === 0;
                    // If sponsored already rewarded
                    this.sponsoredAlreadyRewarded = r.consumersToReward.find(c => c.errorCode === 429) != null;
                    this.sponsoredRewardAttributes = [];
                    if (this.offersSponsor?.[0]) {
                        // Reward sponsor only if reward sponsored succeed
                        if (this.rewardSponsoredSuccessful) {
                            this.consumerService.rewardOne(this.consumerSponsor, this.offersSponsor?.[0], [])
                                .subscribe({
                                    next: (res) => {
                                        this.rewardSponsorSuccessful = res.nbOfNonRewarded === 0;
                                    }, error: () => {
                                        console.error('error while rewarding sponsor with consumer externalId ' + this.consumerSponsor.externalId);
                                        this._snackBar.open('Une erreur est survenue.', 'X');
                                    }
                                });
                        } else {
                            console.error('error while rewarding sponsored ' + this.consumerSponsored.id);
                            this._snackBar.open('Une erreur est survenue.', 'X');
                        }
                    }
                }, error: () => console.error('error while rewarding sponsor with consumer externalId ' + this.consumerSponsor.externalId),
                complete: () => this.rewarding = true
            });
        }
    }

    private filterOffersByConfiguration(offers: Offer[]): Offer[] {
        if (this.mortgageLoan) {
            return offers?.filter(s => !!this.mortgageLoan === !!this.configurationService.getValueByKey(s.configuration, 'mortgageLoan'));
        } else if (this.selfCare) {
            return offers?.filter(s => !!this.selfCare === !!this.configurationService.getValueByKey(s.configuration, 'selfCare'));
        } else {
            return offers?.filter(s => !this.configurationService.getValueByKey(s.configuration, 'mortgageLoan')
                && !this.configurationService.getValueByKey(s.configuration, 'selfCare'));
        }
    }

    sponsoredNeedToWaitBeforeBecomingSponsor(): boolean {
        return !this.giftService.getConfigurationValue('canSponsoredBecomeSponsorAfterDays') && this.sponsorshipService.hasSponsorship;
    }

    generateNewSponsorCode() {
        if (this.sponsorCreated) return;
        this.sponsorshipService.getSponsorshipUserByConsumerId(this.consumerSponsored.id)
            .pipe(
                switchMap(s => this.sponsorshipService.upgradeSponsoredToSponsor(s))
            )
            .subscribe({
                next: () => this.sponsorCreated = true,
                error: () => console.error('error while become sponsorship user with consumer id' + this.consumerSponsored.id)
            });
    }
}
