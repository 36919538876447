import {Component, OnInit} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {GiftService} from '../../../shared/services/gift.service';
import {SponsorshipService} from '../../../shared/services/sponsorship.service';
import {GiftUser, TrackingService} from '@isifid/core';
import {GiftUserService} from '../../../shared/services/gift-user.service';
import {filter} from 'rxjs/operators';

@Component({
    selector: 'app-qrcode',
    templateUrl: './qrcode.component.html',
    styleUrls: ['./qrcode.component.scss'],
    standalone: false
})
export class QrcodeComponent implements OnInit {
    giftUserIsFemale: boolean;
    clientColor: string;
    env = environment;
    loading = true;
    logoUrl: string;
    qrcodeValue: string;
    showModal = false;

    private giftUser: GiftUser;

    constructor(
        public readonly giftService: GiftService,
        private readonly giftUserService: GiftUserService,
        private readonly sponsorshipService: SponsorshipService,
        private readonly trackingService: TrackingService
    ) {
    }

    ngOnInit(): void {
        this.giftUserService.getGiftUser().pipe(filter(s => !!s)).subscribe(s => this.giftUser = s);
        if (this.sponsorshipService.settings) this.qrcodeValue = this.getUrl('qr-code');
        this.getClientLogo();
        // Set css variable with client color
        this.clientColor = this.giftService.client.color3 || 'red';
        this.loading = false;
    }

    getUrl(medium): string {
        const settings = this.sponsorshipService.settings;
        const stringToEncode = `${settings.loginEmail},${settings.loginPassword},${settings.id}`;
        let url = settings.domain;
        if (!url) url = environment.domain;
        if (!url.startsWith('http')) url = `https://` + url;
        const giftUserId = this.giftUser.id;
        const branchCode = this.giftService.convertBranchCodeToString(this.giftUser?.branchList?.[0]);
        const urlBranchCode = branchCode ? '&branchCode=' + branchCode : '';

        return `${url}/auth/${btoa(stringToEncode).replace(/=/gi, '')}?giftUserId=${giftUserId}${urlBranchCode}&utm_source=online&utm_medium=${medium}`;
    }

    getClientLogo(): void {
        this.logoUrl = environment.cdnUrl + '/static/' + this.giftService.client.logoFileName;
    }

    print(female = false): void {
        // Open modal to print qrcode
        this.giftUserIsFemale = female;
        this.showModal = true;
        this.trackingService.trackEvent('print-qr-code', 'client-id ' + this.giftService.client.id, 'giftUserId ' + this.giftUser.id).subscribe();
        setTimeout(() => window.print(), 300);
    }
}
